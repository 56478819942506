<template>
    <div class="videomsg">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">
                    {{
                        $route.query.sourceType == 1
                            ? "采集站"
                            : $route.query.sourceType == 2
                            ? "连心桥"
                            : ""
                    }}
                </div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>
        <div
            class="
                msg-mid
                flex flex-v flex-justify-center flex-pack-center
                color_FF
            "
        >
            <div class="video-msg flex flex-v flex-justify-center">
                <p
                    class="m_b5 font-size28 font-blod my_text_center"
                    v-show="!startPlay"
                >
                    当前视频时长：{{ hour >= 10 ? hour : "0" + hour }}:{{
                        minutes >= 10 ? minutes : "0" + minutes
                    }}:{{ seconds >= 10 ? seconds : "0" + seconds }}
                </p>
                <div class="m_b5 my_text_center">
                    <img
                        src="@/assets/images/1111.png"
                        class="m_b30"
                        alt=""
                        v-if="isShowImg"
                    />
                    <div class="m_b30">
                        <video
                            ref="video"
                            v-if="isHasVideo"
                            class="video-first"
                        ></video>
                        <video
                            class="video-first"
                            :src="playVideo"
                            v-if="playVideo.length > 0"
                            id="videomsg"
                        ></video>
                    </div>

                    <div
                        class="flex flex-justify-center m_b30"
                        v-if="isStartVideo"
                    >
                        <!-- 视频录制或暂停 -->
                        <div
                            @click="getCamera"
                            class="font-size24 video-replay"
                            v-if="startPlay"
                        >
                            开始录制
                        </div>
                        <div
                            @click="stopVideo"
                            class="font-size24 video-replay"
                            v-if="!startPlay"
                        >
                            停止录制
                        </div>
                    </div>
                    <div
                        class="
                            flex flex-justify-center flex-pack-center
                            color_FF
                        "
                        v-if="!isStartVideo"
                    >
                        <div
                            class="video-check font-size22"
                            @click="playRecorder"
                        >
                            查看录像
                        </div>
                        <div
                            class="video-recheck font-size22"
                            @click="rePlayRecorder"
                        >
                            重新录制
                        </div>
                        <div class="font-size22 video-submit" @click="showHide">
                            提交留言
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>

        <!--录视频 start-->
        <div class="video-hide" v-if="isShowHide">
            <div class="hide-box">
                <div
                    class="my_text_center m_b5 font-size28 font-blod hide-close"
                >
                    提交视频留言
                    <div class="hide-close-sun font-size22" @click="closeHide">
                        关闭
                    </div>
                </div>
                <p class="font-size22 flex flex-pack-center m_b30">
                    <van-field
                        v-model="realName"
                        label="真实姓名"
                        placeholder="请输入您的真实姓名"
                        class="input-s"
                    />
                </p>
                <p class="font-size22 flex flex-pack-center">
                    <van-field
                        v-model="contactNum"
                        type="tel"
                        maxlength="11"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        class="input-s"
                    />
                </p>
                <p class="m_b5 input-tips">手机号可以用来快速回访，解决问题</p>
                <div
                    class="flex font-size22 m_b30 input-radio-box"
                    v-if="$route.query.sourceType == 1"
                >
                    <div class="input-radio-title flex flex-justify-center">
                        <span class="font-size24">反馈类型</span>
                    </div>
                    <div class="input-radio-inner">
                        <van-radio-group v-model="radio" direction="horizontal">
                            <van-radio
                                @click="changeInputRadio(item)"
                                :name="index"
                                class="m_b10"
                                v-for="(item, index) in dicItemScreem"
                                :key="index"
                                >{{ item.label }}</van-radio
                            >
                        </van-radio-group>
                    </div>
                </div>

                <p class="input-submit" @click="submit">立即提交</p>
            </div>
        </div>
        <!--录视频 end-->
    </div>
</template>
<script>
import { dateTimeFormat } from "@/request/until.js";
import { videoTopic, getDicScreen } from "@/request/api.js";
import { Toast } from "vant";
import fullScroll from "@/components/fullScroll.vue";
export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            mediaStreamTrack: {}, // 退出时关闭摄像头
            video_stream: "", // 视频stream
            recordedBlobs: [], // 视频音频 blobs
            isRecord: false, // 视频是否正在录制
            playVideo: "", //本地播放的视频
            uploadVideo: null, //上传的视频
            isHasVideo: false, //判断是否正在录视频
            isShowImg: true, //
            isRemoteVideo: false,
            isShowHide: false, //确定上传弹窗
            isStartVideo: true,
            startPlay: true,
            realName: "",
            contactNum: "",
            hour: 0,
            minutes: 0,
            seconds: 0,
            radio: "1",
            style: null,
            dicItemScreem: [],
            sourceType: null,
        };
    },

    created() {
        this.getTime();
    },
    mounted() {
        this.sourceType = Number(this.$route.query.sourceType);
        this.getDicScreenList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },
        // 调用打开摄像头功能
        getCamera() {
            this.startPlay = false;

            // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
            if (navigator.mediaDevices === undefined) {
                navigator.mediaDevices = {};
            }
            this.isShowImg = false;
            this.isHasVideo = true;

            navigator.mediaDevices
                .getUserMedia({
                    video: true,
                    audio: true,
                })
                .then((stream) => {
                    // 摄像头开启成功
                    this.mediaStreamTrack =
                        typeof stream.stop === "function"
                            ? stream
                            : stream.getTracks()[0];
                    this.video_stream = stream;
                    this.$refs.video.srcObject = stream;
                    this.$refs.video.volume = 0;
                    this.$refs.video.play();

                    this.start();
                    this.startTime();
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        // 录制或暂停
        stopVideo() {
            this.isHasVideo = false;
            this.stop();
            this.stopTime();
            this.isStartVideo = false;
        },

        start() {
            this.record();
        },

        rePlayRecorder() {
            this.startPlay = true;
            this.isShowImg = true;
            this.isHasVideo = false;
            this.isStartVideo = true;

            this.playVideo = "";

            this.hour = 0;
            this.minutes = 0;
            this.seconds = 0;
        },

        startTimer() {
            this.seconds++;
            if (this.seconds >= 60) {
                this.seconds = 0;
                this.minutes++;
            }

            if (this.minutes >= 60) {
                this.minutes = 0;
                this.hour++;
            }
        },
        stopTime() {
            clearInterval(this.timer);
        },
        startTime() {
            this.timer = setInterval(this.startTimer, 1000);
        },

        // 视频录制
        record() {
            this.isRecord = true;
            let mediaRecorder;
            let options;
            this.recordedBlobs = [];
            if (typeof MediaRecorder.isTypeSupported === "function") {
                // 根据浏览器来设置编码参数
                if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
                    options = {
                        MimeType: "video/webm;codecs=vp8",
                    };
                } else if (
                    MediaRecorder.isTypeSupported("video/webm;codecs=daala")
                ) {
                    options = {
                        MimeType: "video/webm;codecs=daala",
                    };
                } else if (
                    MediaRecorder.isTypeSupported("video/webm;codecs=h264")
                ) {
                    options = {
                        MimeType: "video/webm;codecs=h264",
                    };
                }
                mediaRecorder = new MediaRecorder(this.video_stream, options);
            } else {
                mediaRecorder = new MediaRecorder(this.video_stream);
            }
            mediaRecorder.start();
            // 视频录制监听事件
            mediaRecorder.ondataavailable = (e) => {
                // 录制的视频数据有效
                if (e.data && e.data.size > 0) {
                    this.recordedBlobs.push(e.data);
                } else {
                    Toast.fail("抱歉，系统出错了，请稍后重新录制");
                }
            };

            // 停止录像后增加下载视频功能，将视频流转为mp4格式
            mediaRecorder.onstop = () => {
                const blob = new Blob(this.recordedBlobs, {
                    type: "video/mp4",
                });
                this.recordedBlobs = [];
                //  将视频链接转换完可以用于在浏览器上预览的本地视频
                const videoUrl = window.URL.createObjectURL(blob);
                this.playVideo = videoUrl;
                this.uploadVideo = blob;
            };
        },

        // 停止录制
        stop() {
            this.isRecord = !this.isRecord;
            if (!this.$refs.video.srcObject) return;
            const stream = this.$refs.video.srcObject;
            const tracks = stream.getTracks();
            // 关闭摄像头和音频
            tracks.forEach((track) => {
                track.stop();
            });
        },

        showHide() {
            this.isShowHide = true;
        },

        closeHide() {
            this.isShowHide = false;
        },

        playRecorder() {
            let video = document.getElementById("videomsg");
            video.play();
        },

        //提交资料
        submit() {
            if (this.$route.query.sourceType == 2) {
                this.style = 0;
                let fd = new FormData();
                fd.append("video", this.uploadVideo, Date.now() + ".mp4");
                fd.append("realName", this.realName);
                fd.append("contactNum", this.contactNum);
                fd.append("style", this.style);
                fd.append("sourceType", this.sourceType);

                videoTopic(fd)
                    .then((res) => {
                        if (res.code == 200) {
                            Toast.success(res.message);
                            setTimeout(() => {
                                this.startPlay = true;
                                this.isShowImg = true;
                                this.isHasVideo = false;
                                this.isStartVideo = true;

                                this.playVideo = "";
                                this.realName = "";
                                this.contactNum = "";
                                this.style = null;
                                this.isShowHide = false;
                                this.hour = 0;
                                this.minutes = 0;
                                this.seconds = 0;
                            }, 1000);
                        } else {
                            Toast.fail(res.message);
                        }
                    })
                    .catch((res) => {
                        Toast.fail(res.message);
                    });
            } else {
                if (this.style == null) {
                    Toast.fail("类别不能为空");
                } else {
                    let fd = new FormData();
                    fd.append("video", this.uploadVideo, Date.now() + ".mp4");
                    fd.append("realName", this.realName);
                    fd.append("contactNum", this.contactNum);
                    fd.append("style", this.style);
                    fd.append("sourceType", this.sourceType);

                    videoTopic(fd)
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success(res.message);
                                setTimeout(() => {
                                    this.startPlay = true;
                                    this.isShowImg = true;
                                    this.isHasVideo = false;
                                    this.isStartVideo = true;

                                    this.playVideo = "";
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.style = null;
                                    this.isShowHide = false;
                                    this.hour = 0;
                                    this.minutes = 0;
                                    this.seconds = 0;
                                }, 1000);
                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((res) => {
                            Toast.fail(res.message);
                        });
                }
            }
        },

        //拿到上传类别下拉筛选
        getDicScreenList() {
            getDicScreen({ code: "feedback_style" }).then((res) => {
                this.dicItemScreem = res.data;
            });
        },

        changeInputRadio(item) {
            this.style = item.id;
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },

        goBack() {
            this.$router.back();
        },
    },
};
</script>
