var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"story"},[_c('full-scroll'),_c('div',{staticClass:"msg-title flex flex-justify-between flex-pack-center color_FF"},[_vm._m(0),_c('div',{staticClass:"msg-title-mid-box font-size32 font-blod flex-1"},[_c('img',{attrs:{"src":require("@/assets/images/tot-top-title.png"),"alt":""}}),_c('div',{staticClass:"msg-title-mid-box-title"},[_vm._v(" "+_vm._s(_vm.$route.query.tid == 19 ? "广阳故事会" : _vm.$route.query.tid == 18 ? "人大故事会" : "")+" ")])]),_c('div',{staticClass:"flex-end flex-1 flex",on:{"click":_vm.backHome}},[_vm._m(1)])]),_c('div',{staticClass:"msg-mid flex flex-v flex-justify-center flex-pack-center"},[_c('div',{staticClass:"notice-box"},[_c('div',{staticClass:"notice-box-list",attrs:{"id":"notice-box-list"}},[_c('ul',[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.newAdverList),function(item,id){return _c('li',{key:id,staticClass:"notice-list flex"},[(
                                      item.cover != '' && item.cover != null
                                  )?_c('div',{staticClass:"m_r20"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]):_vm._e(),(
                                      item.video != '' && item.video != null
                                  )?_c('div',{staticClass:"story-video m_r20"},[_c('img',{attrs:{"src":require("@/assets/play-icon.png"),"alt":""}}),_c('video',{attrs:{"src":item.video + '#t=5.0'}})]):_vm._e(),_c('div',{staticClass:"flex-1 m_r20 flex flex-v flex-justify-center"},[_c('div',{staticClass:"notice-list-box color_FF"},[_c('p',{staticClass:"font-size24 m_b20"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"font-size22 color_9FB"},[_vm._v(" "+_vm._s(item.releaseTime)+" ")])])]),_c('div',{staticClass:"check-info flex flex-justify-center flex-pack-center"},[_c('span',{staticClass:"font-size18 color_FF",on:{"click":function($event){return _vm.goToDetail(item)}}},[_vm._v("查看详情")])])])}),0)],1)])])]),_c('div',{staticClass:"msg-bottom flex flex-justify-between flex-pack-center font-size28"},[_c('div',{staticClass:"my_text_center",on:{"click":_vm.goBack}},[_c('img',{staticClass:"msg-return m_b10",attrs:{"src":require("@/assets/images/return-new.png"),"alt":""}}),_c('p',{staticClass:"font-size24 color_FF"},[_vm._v("返回上级")])]),_c('div',{staticClass:"font-blod my_text_center color_FF"}),_c('div',{staticClass:"my_text_center color_FF"},[_c('p',{staticClass:"font-size24 m_b10 font-blod"},[_vm._v(_vm._s(_vm.todayMtime))]),_c('p',{staticClass:"font-size14"},[_vm._v(_vm._s(_vm.todayTime))])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-pack-center flex-1"},[_c('img',{staticClass:"msg-national m_r20",attrs:{"src":require("@/assets/national_emblem.png"),"alt":""}}),_c('div',{staticClass:"text_align_last"},[_c('p',{staticClass:"font-size24 m_b10"},[_vm._v("广阳镇人大代表之家")]),_c('p',{staticClass:"font-size12"},[_vm._v(" GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my_text_center"},[_c('img',{staticClass:"msg-backhome m_b10",attrs:{"src":require("@/assets/images/backhome-new.png"),"alt":""}}),_c('p',[_vm._v("返回首页")])])
}]

export { render, staticRenderFns }