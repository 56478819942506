import { render, staticRenderFns } from "./storytype.vue?vue&type=template&id=3d0aba94"
import script from "./storytype.vue?vue&type=script&lang=js"
export * from "./storytype.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports