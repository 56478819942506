<template>
    <div class="new-index">
        <div class="index-bg">
            <img src="@/assets/new-index-bg.jpg" class="outer-bg" alt="" />
            <full-scroll></full-scroll>
        </div>
        <div class="index-box">
            <div class="flex flex-justify-between flex-pack-center">
                <img src="@/assets/aaaa.png" alt="" class="details">
                <div class="leader-login flex my_text_center">
                    <div class="
                                leader-login-line
                                flex flex-v flex-justify-center
                            ">
                        <div class="font-size28 m_b10 color_FF font-blod">
                            {{ todayMtime }}
                        </div>
                        <div class="font-size22 m_b10 color_FF font-blod">
                            {{ todayTime }}
                        </div>
                    </div>
                    <div class="flex flex-pack-center">
                        <div class="leader-login-line-l"></div>
                    </div>
                    <div class="leader-login-line" @click="goToBehlfLogin">
                        <img src="@/assets/leader-avatar.png" alt="" class="m_b10" />
                        <p class="color_FF font-size18">{{ leaderLogin }}</p>
                    </div>
                </div>
            </div>
            <div class="my_text_center font-blod m_b90 index-title">
                <img src="@/assets/index-title.png" alt="" />
            </div>
            <div class="scrolling-text-container">
                <p class="scrolling-text">
                    代表履职主阵地 &nbsp;· &nbsp;惠民有感连心桥&nbsp; · &nbsp;基层民主民意窗
                </p>
            </div>
            <div class="flex flex-justify-around">
                <div class="
                            index-inner-bg
                            flex flex-pack-center flex-justify-center
                        " @click="goToLaw">
                    <img src="@/assets/images/index-one.png" class="index-inner-bg-pic" />
                    <div class="index-inner-title font-size32 font-blod color_FF">
                        政策法律宣传
                    </div>
                </div>
                <div class="
                            index-inner-bg-two
                            flex flex-pack-center flex-justify-center
                        " @click="goToLeaderInfo">
                    <img src="@/assets/images/index-two.png" class="index-inner-bg-pic" />
                    <div class="index-inner-title font-size32 font-blod color_FF">
                        代表信息
                    </div>
                </div>
                <div class="
                            index-inner-bg-three
                            flex flex-pack-center flex-justify-center
                        " @click="goToDuty">
                    <img src="@/assets/images/index-three.png" class="index-inner-bg-pic" />
                    <div class="index-inner-title font-size32 font-blod color_FF">
                        履职风采
                    </div>
                </div>
                <div class="
                            index-inner-bg-four
                            flex flex-pack-center flex-justify-center
                        " @click="gotoLeaseFeedback">
                    <img src="@/assets/images/index-four.png" class="index-inner-bg-pic" />
                    <div class="index-inner-title font-size32 font-blod color_FF">
                        民意窗
                    </div>
                </div>
                <div class="
                            index-inner-bg-five
                            flex flex-pack-center flex-justify-center
                        " @click="goToStory">
                    <img src="@/assets/images/index-five.png" class="index-inner-bg-pic" />
                    <div class="index-inner-title font-size32 font-blod color_FF">
                        广阳故事会
                    </div>
                </div>
            </div>
            <div class=" my_text_center support font-size22">
                由重报政情信息中心提供技术支持
            </div>
        </div>
        <!--人大代表登录 start -->
        <div class="login" v-if="isShowBehlfLogin">
            <div class="login-box">
                <div class="my_text_end font-size22" @click="cancelLogin">关闭</div>

                <p class="my_text_center font-blod font-size32 letter-spacing10 m_b20 ">
                    欢迎登录
                </p>
                <p class="my_text_center font-size22 m_b50">
                    广阳镇人大代表网上联络站
                </p>
                <div>
                    <van-field v-model="username" label="账号" placeholder="请输入您的账号" class="index-login-input" />
                </div>
                <div class="m_b5">
                    <van-field v-model="password" label="密码" placeholder="请输入您的密码" type="password"
                        class="index-login-input" />
                </div>

                <div class="my_text_center font-size28 login-sub">
                    <span @click="behalfLogin">登录</span>
                </div>
            </div>
        </div>
        <!--人大代表登录 end -->

        <!--人大代表退出登录 start-->
        <div class="login" v-if="isLeaderLogined">
            <div class="login-box-out my_text_center">
                <div class="login-out">
                    <div class="login-out-re" @click="cancelLoginout">关闭</div>
                </div>
                <img src="@/assets/images/log-out.png" alt="" class="m_b30" />
                <p class="m_b50 font-size28 font-blod">是否确认退出登录</p>
                <div class="flex flex-justify-around font-size22">
                    <p class="loginout-sub cancal" @click="goToReFeedback">
                        处理反馈
                    </p>
                    <p class="loginout-sub sure" @click="sureLoginout">确定</p>
                </div>
            </div>
        </div>
        <!--人大代表退出登录 end-->
    </div>
</template>
<script>
import fullScroll from "@/components/fullScroll";
import { dateTimeFormat } from "@/request/until.js";
import { Toast } from "vant";
import { behlfLogin } from "@/request/api.js";
import { encryptData } from "../commonJS/utils";

export default {
    data() {
        return {
            todayTime: "",
            todayMtime: "",
            leaderLogin: "",
            isShowBehlfLogin: false,
            isLeaderLogined: false,
            username: "",
            password: "",
        };
    },
    components: { fullScroll },
    mounted() {
        this.timer = setInterval(() => {
            this.getTime();
        }, 6 * 60 * 60 * 1000);
    },
    created() {
        this.getTime();
        if (sessionStorage.getItem("islogin")) {
            this.leaderLogin = "退出";
        } else {
            this.leaderLogin = "登录";
        }
    },
    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            let now = new Date();
            let day = now.getDay();
            let weeks = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            );
            let week = weeks[day];
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = week;
        },
        goToBehlfLogin() {
            if (this.leaderLogin == "登录") {
                this.isShowBehlfLogin = true;
                this.isLeaderLogined = false;
            } else {
                this.isLeaderLogined = true;
                this.isShowBehlfLogin = false;
            }
        },

        behalfLogin() {
            behlfLogin({
                username: this.username,
                password: encryptData(this.password, '5987431648958461'),
            }).then((res) => {
                if (res.code == 200) {
                    sessionStorage.setItem(
                        "Authorization",
                        res.data.Authorization
                    );
                    sessionStorage.setItem("islogin", true);
                    this.leaderLogin = "退出";
                    Toast.success("登录成功,正在跳转处理页");
                    setTimeout(() => {
                        this.$router.push("/behalfFeedback");
                    }, 1500);
                } else {
                    Toast.fail(res.message);
                }
            });
        },

        cancelLoginout() {
            this.isLeaderLogined = false;
        },
        cancelLogin() {
            this.isShowBehlfLogin = false;
        },

        goToReFeedback() {
            this.$router.push("/behalfFeedback");
        },

        sureLoginout() {
            sessionStorage.removeItem("Authorization");
            sessionStorage.removeItem("islogin");
            this.isLeaderLogined = false;
            this.leaderLogin = "登录";
        },

        //履职风采列表
        goToDuty() {
            this.$router.push("/duty");
        },

        //法律
        goToLaw() {
            this.$router.push("/law");
        },

        //代表信息
        goToLeaderInfo() {
            this.$router.push("/leaderinfo");
        },

        //民意窗
        gotoLeaseFeedback() {
            this.$router.push("/peopleopinion");
        },

        //故事会
        goToStory() {
            this.$router.push("/storytype");
        },
    },
};
</script>
