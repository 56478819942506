const CryptoJS = require('crypto-js')
export const getQueryString = (data) => {
  let querystring = "";
  data.indexOf("?") === 0
    ? (querystring = data.substring(1))
    : (querystring = data.split("?")[1]);
  const rs = {};
  if (querystring) {
    const kvArr = querystring.split("&");
    kvArr.forEach((item) => {
      const key = item.substring(0, item.indexOf("="));
      const val = item.substring(item.indexOf("=") + 1);
      rs[key] = val;
    });
  }
  return rs;
};

export function dateFormat(str) {
  var dateee = new Date(str).toJSON();
  var date = new Date(+new Date(dateee) + 8 * 3600 * 1000)
    .toISOString()
    .replace(/T/g, " ")
    .replace(/\.[\d]{3}Z/, "");

  return date;
}


/**
 * 加密方法
 * @param {String} word
 * @param {String} key
 * @returns {String}
 */
export function encryptData(word, key) {
    const tKey = CryptoJS.enc.Utf8.parse(checkKeyLength(key))
    const srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, tKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.toString()
    // return encrypted.ciphertext.toString().toUpperCase();
}

/**
 * 检查加密串
 * @param {String} key
 * @returns {String}
 */
function checkKeyLength(key) {
    const len = key ? key.length : 0
    let result = key || ''
    if (len < 16) {
        result = result.padEnd(16, '1')
    } else if (len > 16) {
        result = result.substring(0, 16)
    }
    return result
}
