<template>
    <div class="videomsg">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">
                    {{
                        $route.query.sourceType == 1
                            ? "采集站"
                            : $route.query.sourceType == 2
                            ? "连心桥"
                            : ""
                    }}
                </div>
            </div>
            <div class="flex flex-end flex-1" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>
        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div
                class="
                    video-msg
                    m_b5
                    color_FF
                    flex flex-justify-center flex-pack-center flex-v
                "
            >
                <p class="m_b5 font-size28 font-blod" v-show="!startPlayAudio">
                    当前音频时长：{{ hour >= 10 ? hour : "0" + hour }}:{{
                        minutes >= 10 ? minutes : "0" + minutes
                    }}:{{ seconds >= 10 ? seconds : "0" + seconds }}
                </p>
                <div class="video-msg-audio-box">
                    <img src="@/assets/images/2222.png" alt="" class="m_b50" />
                    <div class="video-msg-upanddown">
                        <span v-for="(item, index) in DB" :key="index"></span>
                    </div>
                </div>

                <div
                    class="flex flex-justify-center flex-pack-center m_b30"
                    v-if="isShowCheck"
                >
                    <div
                        @click="startRecorder"
                        v-if="startPlayAudio"
                        class="audio-start font-size22"
                    >
                        开始录音
                    </div>
                    <div
                        @click="stopRecorder"
                        v-if="!startPlayAudio"
                        class="audio-start font-size22"
                    >
                        完成录音
                    </div>
                </div>
                <div
                    class="
                        flex flex-justify-center flex-pack-center
                        color_FF
                        m_b30
                    "
                    v-if="!isShowCheck"
                >
                    <div class="video-check font-size22" @click="playRecorder">
                        查看录音
                    </div>
                    <div class="video-replay font-size22" @click="rePlay">
                        重新录制
                    </div>
                    <div class="font-size22 video-submit" @click="showHide">
                        提交留言
                    </div>
                </div>
            </div>
        </div>
        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>
            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>

        <!--录视频 start-->
        <div class="video-hide" v-if="isShowHide">
            <div class="hide-box">
                <div
                    class="my_text_center m_b5 font-size28 font-blod hide-close"
                >
                    提交音频留言
                    <div class="hide-close-sun font-size22" @click="closeHide">
                        关闭
                    </div>
                </div>
                <p class="font-size22 flex flex-pack-center m_b30">
                    <van-field
                        v-model="realName"
                        label="真实姓名"
                        placeholder="请输入您的真实姓名"
                        class="input-s"
                    />
                </p>
                <p class="font-size22 flex flex-pack-center">
                    <van-field
                        v-model="contactNum"
                        type="tel"
                        maxlength="11"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        class="input-s"
                    />
                </p>
                <p class="m_b5 input-tips">手机号可以用来快速回访，解决问题</p>
                <div
                    class="flex font-size22 m_b30 input-radio-box"
                    v-if="$route.query.sourceType == 1"
                >
                    <div class="input-radio-title flex flex-justify-center">
                        <span class="font-size24">反馈类型</span>
                    </div>
                    <div class="input-radio-inner">
                        <van-radio-group v-model="radio" direction="horizontal">
                            <van-radio
                                @click="changeInputRadio(item)"
                                :name="index"
                                class="m_b10"
                                v-for="(item, index) in dicItemScreem"
                                :key="index"
                                >{{ item.label }}</van-radio
                            >
                        </van-radio-group>
                    </div>
                </div>
                <p class="input-submit" @click="submit">立即提交</p>
            </div>
        </div>
        <!--录视频 end-->

        <!-- 提交留言 start-->
        <div class="video-hide" v-if="isShowSubhide">
            <div class="hide-box">
                <p class="my_text_center m_b5 font-size28 font-blod">
                    留言提交成功
                </p>
                <div class="my_text_center m_b5">
                    <img src="@/assets/aaaa.png" alt="" />
                </div>
                <p class="my_text_center m_b5">
                    您的留言已成功提交，我们会尽快处理，想随时了解处理动态
                    可扫描二维码关注我们
                </p>
            </div>
        </div>
        <!--- 提交留言  end-->
    </div>
</template>
<script>
import { dateTimeFormat } from "@/request/until.js";
import { audioTopic, getDicScreen } from "@/request/api.js";
import { Toast } from "vant";
import Recorder from "js-audio-recorder";
import fullScroll from "@/components/fullScroll";
export default {
    data() {
        return {
            recorder: new Recorder(),
            videoAttachment: [],
            isShowVideo: false,
            isShowCheck: true, //是否点击了 完成录音
            startPlayAudio: true, //是否点击了 开始录音
            realName: "",
            contactNum: "",
            style: null,
            todayMtime: "",
            todayTime: "",
            previewVideo: null,
            isShowHide: false,
            isShowSubhide: false,
            isShowUploderVideo: true,
            isStartAudio: false, //判断是否正在录音

            seconds: 0,
            minutes: 0,
            hour: 0,
            radio: "1",
            dicItemScreem: [],

            DB: null,
            sourceType: null,
        };
    },
    components: {
        fullScroll,
    },
    created() {
        this.getTime();
    },
    mounted() {
        this.sourceType = Number(this.$route.query.sourceType);
        this.getDicScreenList();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        startRecorder() {
            this.recorder.start().then(
                () => {
                    // 开始录音
                    this.isStartAudio = true;
                    this.startPlayAudio = false;
                    this.start();
                    this.startPlay();
                },
                (error) => {
                    // 出错了
                    Toast.fail(
                        "设备不支持",
                        `${error.name} : ${error.message}`
                    );
                }
            );
        },

        stopRecorder() {
            if (this.isStartAudio == true) {
                this.recorder.stop();
                this.isShowCheck = false;
                this.stop();
            } else {
                Toast.fail("暂无录音内容");
            }
        },

        playRecorder() {
            this.recorder.play();
        },

        rePlay() {
            this.isStartAudio = false;
            this.startPlayAudio = true;
            this.isShowCheck = true;
            this.seconds = 0;
            this.minutes = 0;
            this.hour = 0;
            this.destroyRecorder();
        },
        destroyRecorder() {
            this.recorder.destroy().then(function () {
                this.recorder = new Recorder();
            });
        },

        startTimer() {
            this.seconds++;
            if (this.seconds >= 60) {
                this.seconds = 0;
                this.minutes++;
            }

            if (this.minutes >= 60) {
                this.minutes = 0;
                this.hour++;
            }
        },

        audioPlay() {
            this.DB = Math.ceil(Math.random() * 10);
        },

        startPlay() {
            this.playTimer = setInterval(this.audioPlay, 200);
        },

        stop() {
            clearInterval(this.timer);
            clearInterval(this.playTimer);
            setTimeout(() => {
                this.DB = null;
            }, 300);
        },
        start() {
            this.timer = setInterval(this.startTimer, 1000);
        },
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },

        //提交资料
        submit() {
            if (this.$route.query.sourceType == 2) {
                this.style = 0;
                let fd = new FormData();
                fd.append(
                    "audio",
                    this.recorder.getWAVBlob(),
                    Date.now() + ".mp3"
                );
                fd.append("realName", this.realName);
                fd.append("contactNum", this.contactNum);
                fd.append("style", this.style);
                fd.append("sourceType", this.sourceType);
                audioTopic(fd)
                    .then((res) => {
                        if (res.code == 200) {
                            Toast.success(res.message);
                            setTimeout(() => {
                                this.isStartAudio = false;
                                this.startPlayAudio = true;
                                this.isShowCheck = true;
                                this.seconds = 0;
                                this.minutes = 0;
                                this.hour = 0;
                                this.realName = "";
                                this.contactNum = "";
                                this.style = null;
                                this.isShowHide = false;
                                this.destroyRecorder();
                            }, 1000);
                        } else {
                            Toast.fail(res.message);
                        }
                    })
                    .catch((res) => {
                        Toast.fail(res.message);
                    });
            } else {
                if (this.style == null) {
                    Toast.fail("类别不能为空");
                } else {
                    let fd = new FormData();
                    fd.append(
                        "audio",
                        this.recorder.getWAVBlob(),
                        Date.now() + ".mp3"
                    );
                    fd.append("realName", this.realName);
                    fd.append("contactNum", this.contactNum);
                    fd.append("style", this.style);
                    fd.append("sourceType", this.sourceType);
                    audioTopic(fd)
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success(res.message);
                                setTimeout(() => {
                                    this.isStartAudio = false;
                                    this.startPlayAudio = true;
                                    this.isShowCheck = true;
                                    this.seconds = 0;
                                    this.minutes = 0;
                                    this.hour = 0;
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.style = null;
                                    this.isShowHide = false;
                                    this.destroyRecorder();
                                }, 1000);
                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((res) => {
                            Toast.fail(res.message);
                        });
                }
            }
        },

        closeHide() {
            this.isShowHide = false;
        },

        //显示弹窗
        showHide() {
            if (this.isStartAudio == false) {
                Toast.fail("请先完成录音");
            } else {
                this.isShowHide = true;
            }
        },

        goBack() {
            this.$router.back();
        },

        //拿到上传类别下拉筛选
        getDicScreenList() {
            getDicScreen({ code: "feedback_style" }).then((res) => {
                this.dicItemScreem = res.data;
            });
        },

        changeInputRadio(item) {
            this.style = item.id;
        },
    },
};
</script>
